import '../styles/Monster.sass';
import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from "@tanstack/react-query";
import FuzzySearch from 'fuzzy-search';
import ReactPaginate from 'react-paginate';
import { useModal } from '../UseModal.js';

async function fetchData() {
	return await axios.get('/monster').then(x => x?.data ?? []).catch(e => {console.error(e); return []});
}

async function fetchStatsData(id) {
	return await axios.get('/monster/' + id).then(x => x?.data ?? {}).catch(e => {console.error(e); return {}});
}

function trimEllip(str, length) {
	if(str === undefined)
		return undefined;
	return str.length > length ? str.substring(0, length) + "..." : str;
}
function bind(min, med, max) { return Math.max(min, Math.min(med,max)); }

function MonsterDialog(monster) {
	const { data, isLoading, error } = useQuery({
    queryKey: ["monster", monster?.id],
    queryFn: async () => monster?.id === undefined ? {} : await fetchStatsData(monster?.id),
    staleTime: 10 * 1000
  });

	if(isLoading)
		return (<span>Loading...</span>);
	if(error)
		return (<span>Error: {error}</span>);
	if(monster === undefined || data === undefined)
		return (<span>no monster here.</span>);
  const initiative = data.initiative ?? data.dex_bonus;
	return (
		<>
		<h1><a href={monster.link} target="_blank">{monster.name}</a></h1>
    <div><label>Source book:</label> <span>{data.source}</span></div>
		<div>
      <table style={{width: '100%'}}>
        <thead>
          <tr>
            <th scope="col">CR</th>
            <th scope="col">AC</th>
            <th scope="col">HP</th>
            <th scope="col">Initiative</th>
            <th scope="col">Speed</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.cr}</td>
            <td>{data.ac}</td>
            <td>{data.hp}</td>
            <td>{initiative >= 0 ? '+' : ''}{initiative}</td>
            <td>{data.speed_walk}
                {data.speed_fly !== undefined ? ('. ' + data.speed_fly + ' flying') : ''}
                {data.speed_swim !== undefined ? ('. ' + data.speed_swim + ' swim') : ''}
                {data.speed_burrow !== undefined ? ('. ' + data.speed_burrow + ' swim') : ''}
                {data.speed_climb !== undefined ? ('. ' + data.speed_climb + ' climb') : ''}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
		<div>
      <table style={{width: '100%'}}>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">con</th>
            <th scope="col">str</th>
            <th scope="col">dex</th>
            <th scope="col">int</th>
            <th scope="col">wis</th>
            <th scope="col">cha</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>stat</td>
            <td>{data.con}</td>
            <td>{data.str}</td>
            <td>{data.dex}</td>
            <td>{data.int}</td>
            <td>{data.wis}</td>
            <td>{data.cha}</td>
          </tr>
          <tr>
            <td>bonus</td>
            <td>{data.con_bonus >= 0 ? '+' : ''}{data.con_bonus}</td>
            <td>{data.str_bonus >= 0 ? '+' : ''}{data.str_bonus}</td>
            <td>{data.dex_bonus >= 0 ? '+' : ''}{data.dex_bonus}</td>
            <td>{data.int_bonus >= 0 ? '+' : ''}{data.int_bonus}</td>
            <td>{data.wis_bonus >= 0 ? '+' : ''}{data.wis_bonus}</td>
            <td>{data.cha_bonus >= 0 ? '+' : ''}{data.cha_bonus}</td>
          </tr>
        </tbody>
      </table>
    </div>
		<h2>Attacks:</h2>
		<div dangerouslySetInnerHTML={{ __html: data.attack_desc }} />
		<h2>Description:</h2>
		<div dangerouslySetInnerHTML={{ __html: monster.description }} />
		</>
	);
}

export default function Monsters() {
	const [searchText, setSearchText] = useState('');
	const [crFilter, setCrFilter] = useState(-1);
	const [monsterDialogElement, openMonsterDialog] = useModal(MonsterDialog);
	const [paging, setPaging] = useState({ perPage: 10, pageNumber: 1 });
	const { data, isLoading, error } = useQuery({queryKey: ["allmonsters"], queryFn: fetchData, staleTime: 10 * 1000});
	if(isLoading) return (<div>Loading</div>);
	if(error) return (<div>Error: {error.message}</div>);

	let monsters = data;
	let allCr = monsters.map(x => x.cr);
	allCr = [...new Set(allCr)];
	allCr.sort((a, b) => a - b);

	if(crFilter != -1)
		monsters = monsters.filter(x => x.cr == crFilter);
	if(searchText !== undefined && searchText !== '') {
		const splits = searchText.split('and').flatMap(x => x.split('&'));
		for(let s of splits) {
			s = s.trim();
			if(s === '')
				continue;
			const searcher = new FuzzySearch(monsters, ['name', 'sourceBook'], {
				caseSensitive: false,
			});
			monsters = searcher.search(s);
		}
	}
	const maxPageNumber = Math.ceil(Math.max(1, monsters.length) / paging.perPage);
	monsters = monsters.slice(bind(0, (paging.perPage * (paging.pageNumber - 1)), monsters.length - 2),
		bind(1, (paging.perPage * paging.pageNumber), monsters.length - 1));
	return (
	<>
		{monsterDialogElement}
		<input type="search" spellCheck="false" placeholder="Search" value={searchText}
		onChange={e => {setPaging({pageNumber: 1, perPage: paging.perPage}); setSearchText(e.target.value.toLowerCase())}}/>

		<span> CR: </span>
		<select onChange={e => setCrFilter(e.target.value)}>
			<option value={-1}>-</option>
			{allCr.map((v, i) => <option key={i} value={v}>{v}</option>)}
		</select>

		<table style={{width: '100%'}}>
			<thead>
				<tr>
					<th scope="col">Name</th>
					<th scope="col">CR</th>
					<th scope="col">Source</th>
				</tr>
			</thead>
			<tbody>
				{monsters.map((item, i) => (
				<tr key={i}>
					<td><button onMouseDown={() => openMonsterDialog(item)} className="primary">{item.name}</button></td>
					<td>{item.cr}</td>
					<td>{trimEllip(item.sourceBook, 30)}</td>
				</tr>
				))}
			</tbody>
		</table>
		<div className='center_contents'>
			<ReactPaginate
				breakLabel="..."
				nextLabel="next >"
				onPageChange={(e) => setPaging({...paging, pageNumber: e.selected + 1})}
				pageRangeDisplayed={5}
				pageCount={maxPageNumber}
				previousLabel="< previous"
				renderOnZeroPageCount={null}
			/>
		</div>

		<div>
			<span>Per Page:</span>
			<select onChange={e => setPaging({perPage: e.target.value, pageNumber: paging.pageNumber})}>
				{[10, 25, 50].map(i => <option key={i} value={i}>{i}</option>)}
			</select>
		</div>
	</>
	);
}
