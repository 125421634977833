import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchData() {
  return axios.get('character').then(x => x?.data ?? []).catch(console.error);
}

export default function CharacterListPage() {
	const { data, isLoading, error } = useQuery({queryKey: ["character_list"], queryFn: fetchData, staleTime: 10 * 1000});
	if(isLoading)
    return (<div>Loading...</div>);
  if(error)
    return (<div>{error}</div>);
  //const list = [
    //{text: 'Nillbert', value: 5}
  //]
  //const encourageButtonText = [
    //'Conquor',
    //'Yeah',
    //'Great choice',
    //'Sweet',
    //'Sick',
    //'Freakin Heck Yeah',
    //'Great',
    //'Awesome',
  //];
  return (
    <>
      <h1>Select a character</h1>
      <table>
				<thead>
					<tr><th scope="col">Name</th></tr>
				</thead>
				<tbody>
					{data.map(character => (
						<tr key={character.id} style={{cursor: 'pointer'}}>
							<td>{character.name}</td>
						</tr>
					))}
				</tbody>
			</table>
    </>
    );
}
