import { useModal } from '../UseModal.js';
import { Dice } from '../services/Dice.js';
import { calculate } from '../services/MyMath.js';
import {ReactComponent as Delete} from '@material-design-icons/svg/filled/delete.svg';
import {ReactComponent as Save} from '@material-design-icons/svg/filled/save.svg';
import {ReactComponent as Add} from '@material-design-icons/svg/filled/add_circle.svg';
import { useUserStore } from "../UseUserStore.js";

const defaultStats = {ac: 14, con: 14, str: 13, dex: 16, wis: 8, int: 18, cha: 10};
const crHeaderName = 'CR target';
export const fiveEStats = [
	...['ac', 'con', 'str', 'dex', 'wis', 'int', 'cha'].map(s => ({name: s, default: (defaultStats[s] ?? 10).toString(), readonly: false, fill_lower: true})),
	{name: 'hp', default: '10 + ({level} * {conBonus})', readonly: false, fill_lower: true},
	{name: 'prof', default: '2 + floor(({level} - 1)/4)', readonly: false, fill_lower: false},
	{name: 'atks', default: 'floor({level}/5)+1', readonly: false},
	{name: '+toHit', default: '10', readonly: false},
	{name: 'DC', default: 'floor((max({wis}, max({int}, {cha})) - 10) / 2) + {prof} + 8', readonly: false},
	{name: crHeaderName, default: 'floor(({level} - 1) * 30.0 / 20.0)', readonly: false},
].map(fillCalculatedFnForHeader);

function editHeaderModalContent(state, setState, toggle) {
	if(state === undefined) return (<div>no state.</div>);
	let dice = undefined;
	if(!!state.dice_value_string) {
		dice = new Dice(state.dice_value_string);
		dice.variables = {...dice.variables};
	}
	if(!!dice)
		setState({...state, dice, default: () => dice.calc()});
	let fillDefault = e => {
		let nState = {...state, default: e.target.value};
		nState = fillCalculatedFnForHeader(nState);
		setState(nState);
	}
	let changeName = (e) => {
		setState({...state, prevName: (state.prevName ?? state.name), name:e.currentTarget.value});
	}
	return (
		<>
		<h2>Edit - [{state.name}]</h2>
		<label>label<input type="text" value={state.name} onChange={changeName} /></label>
		<label>Override propegates to next levels<input type="checkbox" value={state.fill_lower} onChange={e => {setState({...state, fill_lower: e.target.checked})}}/></label>
		<label>Default value</label><input type="text" value={state.default} onChange={fillDefault}/>
		<div className='button_group'>
			<button disabled={state.value===''} className='secondary' onMouseDown={() => {toggle();}}><Save/></button>
			<button className='warn' onMouseDown={() => toggle({...state, deleteMe: true})}><Delete/></button>
		</div>
		</>
	);
}

export function fillCalculatedFnForHeader(header) {
	if(!header || !header.default || header.default === '')
		return header;
	try
	{
		header.default_fn = calculate(header.default);
		//console.log(header.default_fn);
	}
	catch(e){
		console.log(header.default);
		throw e;
	}
	return header;
}

export function fillCharacterLevelStats(state, stats, max_level) {
	state = JSON.parse(JSON.stringify(state));
	for(var level = 1; level <= max_level; ++level) {
		if(state[level] === undefined)
			state[level] = {};
		let character = state[level];
		character.level = level;
		let flatData = {level};
		for(var stat of stats) {
			if(character[stat.name]?.user_set === true) {}
			else if(level === 1 || stat.fill_lower !== true) {
				if(!!stat.default && typeof stat.default_fn === 'function')
					character[stat.name] = { value: stat.default_fn(flatData) };
				else
					character[stat.name] = { value: 0 };
			}
			else
				character[stat.name] = { value: state[level - 1][stat.name].value };
			flatData[stat.name] = character[stat.name].value;
		}
	}
	return state;
}

function EditCellModalContent(state, setState, toggle) {
	if(state === undefined) return (<div>no state.</div>);
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			toggle();
		}
	}
	return (
		<>
			<h2>{state.stat} @ level {state.level}</h2>
			<label><input type="text" value={state.value} onKeyDown={handleKeyDown} onChange={(e) => setState({...state, value: e.target.value})}/></label>
			<div className="button_group">
				<button disabled={state.value===''} className='secondary' onMouseDown={() => {toggle(state);}}>Set</button>
				<button className='warn' onMouseDown={() => {toggle({...state, delete: true});}}>Delete</button>
			</div>
		</>
	);
}

export default function CharacterStatsPerLevel() {
	let characterName = 'Nillbert';
	const { data:character_state_data, mutate: set_stored_character_data } = useUserStore('character_stats', characterName, {});
	const { data:row_header_data, mutate: set_stored_headers } = useUserStore('character_headers', characterName, fiveEStats);
  let row_headers = row_header_data.map(x => fillCalculatedFnForHeader(x));
  let character = fillCharacterLevelStats(character_state_data, row_headers, 20);

	let [cellEditDialog, toggleCellModal] = useModal(EditCellModalContent, cellSave => {
		let next_set = JSON.parse(JSON.stringify(character_state_data));
		if(next_set === undefined)
			next_set = {};
		if(next_set[cellSave.level] === undefined)
			next_set[cellSave.level] = {};
		if(!!cellSave.delete)
			delete next_set[cellSave.level][cellSave.stat];
		else
			next_set[cellSave.level][cellSave.stat] = { value: cellSave.value, user_set: true };
		set_stored_character_data(next_set);
	});

	let [headerEditDialog, toggleHeaderModal] = useModal(editHeaderModalContent, header => {
		let hi = row_headers.findIndex(r => r.name === (header.prevName ?? header.name));
		delete header.prevName;
		let rows = row_headers;
		if (header.deleteMe === true) {
			console.log('delete me?');
			rows = row_headers.filter((_, i) => i !== hi);
		}
		else {
			rows = row_headers.slice(0);
			rows[hi] = header;
		}
		set_stored_headers(rows);
	});

	let addHeader = () => {
		let nextH = [...row_headers, {name: 'untitled', default: '0', readonly: false, fill_lower: true}];
		nextH = nextH.map(h => fillCalculatedFnForHeader(h));
		set_stored_headers(nextH);
	};

	return (
		<>
			<div>
				<div>
					<button className='primary' onMouseDown={() => addHeader()}><Add/></button>
					<button className='primary' onMouseDown={() => {set_stored_headers(fiveEStats);set_stored_character_data({});}}>Reset character</button>
				</div>
			</div>
			<table>
				<thead>
					<tr><th scope="col">Lvl</th>{row_headers.map(stat => (<th key={stat.name} scope="col" className="cell-edit" onMouseDown={() => {toggleHeaderModal(stat)}}>{stat.name}</th>))}</tr>
				</thead>
				<tbody>
					{Array(20).fill(0).map((_, level) => level + 1).map(level => (
						<tr key={level}>
							<td>{level}</td>
							{row_headers.map(stat => (
								<td key={stat.name} className={stat.readonly ? '' : 'cell-edit'} onMouseDown={() => {if(!stat.readonly){
									toggleCellModal({value: character[level][stat.name].value, level: level, stat: stat.name});
								}}}
								>
									<span className={character[level][stat.name].user_set === true ? 'user_set' : 'user_not_set'}>
										{character[level][stat.name].value}
									</span>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		{cellEditDialog}{headerEditDialog}
		</>
	);
}
